






import {Component, Vue, Watch} from 'vue-property-decorator';

@Component({
    components: {},
})
export default class NoRobotV2 extends Vue {
    private divId:string= 'googleCaptureDiv';
    private siteKey: string = "6LdlNvYdAAAAAOWUmGyH7foKIav0vmUV21zJm7Sh";

    @Watch('$vuetify.theme.dark')
    private darkmodeChanged() {
        this.$router.go(0);
    }
    private get theme(): string {
        if(this.$vuetify.theme.dark) {
            return "dark";
        }
        return "light";
    }

    mounted() {
        const doc = document;
        const script = doc.createElement('script')
        script.src = 'https://www.google.com/recaptcha/api.js?hl=' + this.$store.getters.lang;
        script.async = true;
        script.defer = true;
        script.onload = () => {
            //@ts-ignore
            window.grecaptcha.ready(function () {
                //@ts-ignore
                // window.grecaptcha.render("googleRecaptchaDiv", {
                //     "sitekey": sk
                // });
            });
        };
        doc.head.appendChild(script);
    }

    public async reset(): Promise<void> {
        // @ts-ignore
        await window.grecaptcha.ready(async function () {
            //@ts-ignore
            await window.grecaptcha.reset();
        });
    }

    public async validate(): Promise<boolean> {
        //@ts-ignore
        const response = await window.grecaptcha.getResponse();
        const result = await this.$webApi.isNoRobot({
            response: response
        });

        return result.data.success;
    }
}

