
















































import {Component, Ref, Vue} from 'vue-property-decorator';
import NoRobot from "@/components/global/NoRobotV2.vue";
import ViewDesign from "@/components/global/ViewDesign.vue";

@Component({
    components: {ViewDesign, NoRobot},
})
export default class PasswordReset extends Vue {
    @Ref()
    private recapture!:NoRobot;
    private valid: boolean = true;
    private email: string = "";
    private success: boolean = false;
    private emailRules = [
        v => !!v || this.$tc('register.new.validation.emailRequired'),
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.$tc('register.new.validation.emailValid'),
    ];

    private loading: boolean = true;

    mounted() {
        this.valid = false;
        this.loading = false;
    }

    private async validate() {
        //@ts-ignore
        if (this.$refs.registerForm.validate()) {
            const result = await this.recapture.validate();

            if(result) {
                await this.resetPassword(this.email);
            } else {
                await this.recapture.reset();
            }
        }
    }

    private async resetPassword(email: string) {
        this.success = await this.$webApi.passwordReset(email, this.$store.getters.lang).catch(async(reason)=>{

           await this.recapture.reset();
           return Promise.reject();
        });

    }
}

